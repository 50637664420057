<template>
    <Layout>
      <el-form
       :rules="rules" ref="formData"
        :model="formData"
        label-width="100px"
        style="max-width: 800px; margin: 50px auto;"
      >
      <el-row>
      <el-col :span="12">
      <el-form-item class="bottom" label="证件照" prop="avatar" :rules="[{ required: true, validator: validateAvatar, trigger: 'blur' }]">
          <div class="avatar-uploader-wrapper">
            <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
             :http-request="httpRequests"
            accept=".png,.jpg,.jpeg"
        >
            <img v-if="formData.avatar" :src="formData.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        </el-form-item>
        <div style="color: black;font-size: 12px;margin-left:6rem;" class="">*请上传免冠正面登记照，大小不超过2m</div>
        <el-form-item label="真实姓名" :required="true" prop="realname" :rules="[{ required: true, validator: validateRealname, trigger: 'blur' }]">
          <el-input v-model="formData.realname" placeholder="请输入真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" :required="true" prop="phone" :rules="[{validator: validatePhone, trigger: 'blur' }]">
        <el-input v-model="formData.phone" placeholder="请输入手机号"></el-input>
    </el-form-item>
        <el-form-item label="性别" :required="true" prop="sex" :rules="[{validator: validateSex, trigger: 'blur' }]">
        <el-select v-model="formData.sex" placeholder="请选择性别">
          <el-option value="男">男</el-option>
          <el-option value="女">女</el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="学历" prop="degree" :rules="[{validator: validateEdu, trigger: 'blur' }]">
    <el-select v-model="formData.degree" placeholder="请选择学历" >
      <el-option
        v-for="item in educationOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="学历是否全日制" :required="true" prop="is_fulltime">
        <el-select v-model="formData.is_fulltime" placeholder="请选择是否全日制">
          <el-option value="是">是</el-option>
          <el-option value="否">否</el-option>
        </el-select>
    </el-form-item>
        <el-form-item label="出生年月" :required="true" prop="birthday" :rules="[{validator: validatebirthday, trigger: 'blur' }]">
            <el-date-picker v-model="formData.birthday" type="date" placeholder="选择日期">
        </el-date-picker>
        </el-form-item>
        <el-form-item label="专业类型" :required="true" prop="subject_type"  :rules="[{validator: validatesubjectType, trigger: 'blur' }]">
          <el-select v-model="formData.subject_type" @change="loadMinorCategories">
      <el-option
        v-for="category in majorCategories"
        :key="category.id"
        :label="category.name"
        :value="category.name"
      />
    </el-select>
    </el-form-item>
    <el-form-item label="专业" :required="true" prop="subject" :rules="[{validator: validatesubject, trigger: 'blur' }]">
        <!-- 专业小类下拉框 -->
    <el-select v-model="formData.subject">
      <el-option
        v-for="category in minorCategories"
        :key="category.id"
        :label="category.name"
        :value="category.name"
      />
    </el-select>
    </el-form-item>
    <el-form-item label="毕业学校" :required="true" prop="subject" :rules="[{validator: validateschool, trigger: 'blur' }]">
        <el-input v-model="formData.school" placeholder="请输入毕业学校"></el-input>
    </el-form-item>
      </el-col>
      <el-col :span="12" class="elcol2">
       <el-form-item label="身份证正面" :required="true" prop="idcard" :rules="[{validator: validateidcard2, trigger: 'blur' }]">
        <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
             :http-request="httpRequestidcard"
            accept=".png,.jpg,.jpeg"
        >
        <template v-if="formData.idcard">
          <img :src="formData.idcard" class="avatar2"/>
        </template>
        <template v-else>
          <img :src="defaultUrl" class="avatar2" />
        </template>
        </el-upload>
       </el-form-item>
       <el-form-item label="身份证反面" :required="true" prop="idcard2" :rules="[{validator: validateidcard2, trigger: 'blur' }]">
        <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
             :http-request="httpRequestidcard2"
            accept=".png,.jpg,.jpeg"
        >
        <template v-if="formData.idcard2">
          <img :src="formData.idcard2" class="avatar2"/>
        </template>
        <template v-else>
          <div class="avatar-uploader-wrapper">
              <div class="avatar-uploader2">
                <img :src="defaultUrl" class="avatar2"/>
              </div>
          </div>
        </template>
        </el-upload>
       </el-form-item>
       <el-form-item label="毕业证书" :required="true" prop="grad" :rules="[{validator: validategrad, trigger: 'blur' }]">
        <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
             :http-request="httpRequestgrad"
            accept=".png,.jpg,.jpeg"
        >
        <template v-if="formData.grad">
          <img :src="formData.grad" class="avatar2"/>
        </template>
        <template v-else>
          <div class="avatar-uploader-wrapper">
              <div class="avatar-uploader2">
                <img :src="defaultUrl" class="avatar2"/>
              </div>
          </div>
        </template>
        </el-upload>
       </el-form-item> 
    <template v-for="(field, index) in fieldlist" :key="index">
  <el-form-item :label="field.fieldname" :required="field.is_need" style="display: flex; align-items: center;" :prop="field.fieldname" :rules="[{validator: validatefieldname, trigger: 'change' }]">
    <template v-if="field.type === 'date'">
      <el-date-picker 
        v-model="formData[field.id]" 
        :placeholder="'请输入'+ field.fieldname"
      ></el-date-picker>
    </template>
    <template v-else-if="field.type=='pic'">
      <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
             :http-request="httpRequests2"
            accept=".png,.jpg,.jpeg"
        >
        <template v-if="formData[field.id]">
          <template v-for="(it,id) in formData[field.id]">
            <img :src="it" class="avatar2"/>
          </template>
          <img src="/image-2.png" width="100" height="100"/>
        </template>
        <template v-else>
            <img src="/image-1.png" class="avatar2"/>
          </template>
        </el-upload>
    </template>
    <template v-else-if="field.type === 'enum'">
      <el-select v-model="formData[field.id]" :placeholder="'请输入'+ field.fieldname">
        <el-option v-for="(option, index2) in field.enums" :key="index2" :label="option.label" :value="option"></el-option>
      </el-select>
    </template>
    <template v-else-if="field.type === 'phone'">
      <el-input v-model="formData[field.id]" :placeholder="'请输入'+ field.fieldname" @blur="validatePhone"></el-input>
    </template>
    <template v-else-if="field.type === 'number'">
      <el-input v-model="formData[field.id]" :placeholder="'请输入'+ field.fieldname" @blur="validateNumber"></el-input>
    </template>
    <template v-else>
      <el-input v-model="formData[field.id]" :placeholder="'请输入'+ field.fieldname"></el-input>
    </template>
  </el-form-item>
</template>
</el-col>
</el-row>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">提交</el-button>
        </el-form-item>
      </el-form>
    </Layout>
  </template>
  
<script>
import axios from 'axios';
import {baseURL } from '@/api/base.js';
export default {
  beforeMount(){
      axios.post(baseURL + '/api/report/isgetreport', {id:this.id}, {
             headers: {
               Authorization: `Bearer ${localStorage.getItem('token')}`
             }
           })
           .then((response) => {
            if(response.data.code==503){
              this.$router.push('/')
            }
           })
           .catch((error) => {
             console.error('提交错误:', error);
          });
    },
    props: ['id'],
  data() {
    return {
      rules: {
          phone: [
            { validator: this.validatePhone, trigger: 'blur' }
          ]
        },
        formData:{
          avatar: "/image-1.png",
          realname: "",
          phone: "",
          degree: "",
          is_fulltime: "",
          school: "",
          subject_type: "",
          sex: "",
          birthday: "",
          subject: "",   
      },
        educationOptions: [
        { label: "高中/中专", value: "高中/中专" },
        { label: "专科", value: "专科" },
        { label: "本科", value: "本科" },
        { label: "硕士研究生", value: "硕士研究生" },
        { label: "博士研究生", value: "博士研究生" },
      ],
      majorCategories: [
    {
        id: 1,
        name: "哲学",
        minors: [
            { id: 11, name: "哲学" },
            { id: 12, name: "逻辑学" },
            { id: 13, name: "宗教学" },
            { id: 14, name: "伦理学" },
            { id: 999, name: "其他" } // 添加了一个"其他"选项，这里假设id为999（可根据实际情况调整合适的唯一标识id）
        ]
    },
    {
        id: 2,
        name: "经济学",
        minors: [
            { id: 21, name: "经济学" },
            { id: 22, name: "经济统计学" },
            { id: 23, name: "国民经济管理" },
            { id: 24, name: "资源与环境经济学" },
            { id: 25, name: "财政学" },
            { id: 26, name: "税收学" },
            { id: 27, name: "金融学" },
            { id: 28, name: "金融工程" },
            { id: 29, name: "保险学" },
            { id: 30, name: "投资学" },
            { id: 999, name: "其他" }
        ]
    },
    {
        id: 3,
        name: "法学",
        minors: [
            { id: 31, name: "法学" },
            { id: 32, name: "知识产权" },
            { id: 33, name: "监狱学" },
            { id: 34, name: "信用风险管理与法律防控" },
            { id: 35, name: "国际经贸规则" },
            { id: 36, name: "政治学与行政学" },
            { id: 37, name: "国际政治" },
            { id: 38, name: "外交学" },
            { id: 39, name: "社会学" },
            { id: 40, name: "社会工作" },
            { id: 41, name: "人类学" },
            { id: 999, name: "其他" }
        ]
    },
    {
        id: 4,
        name: "教育学",
        minors: [
            { id: 41, name: "教育学" },
            { id: 42, name: "科学教育" },
            { id: 43, name: "人文教育" },
            { id: 44, name: "教育技术学" },
            { id: 45, name: "学前教育" },
            { id: 46, name: "特殊教育" },
            { id: 47, name: "体育教育" },
            { id: 48, name: "运动训练" },
            { id: 49, name: "社会体育指导与管理" },
            { id: 50, name: "武术与民族传统体育" },
            { id: 999, name: "其他" }
        ]
    },
    {
        id: 5,
        name: "文学",
        minors: [
            { id: 51, name: "汉语言文学" },
            { id: 52, name: "汉语言" },
            { id: 53, name: "汉语国际教育" },
            { id: 54, name: "中国少数民族语言文学" },
            { id: 55, name: "古典文献学" },
            { id: 56, name: "英语" },
            { id: 57, name: "俄语" },
            { id: 58, name: "德语" },
            { id: 59, name: "法语" },
            { id: 60, name: "西班牙语" },
            { id: 61, name: "日语" },
            { id: 62, name: "韩语" },
            { id: 63, name: "新闻学" },
            { id: 64, name: "广播电视学" },
            { id: 65, name: "广告学" },
            { id: 66, name: "传播学" },
            { id: 67, name: "编辑出版学" },
            { id: 68, name: "网络与新媒体" },
            { id: 69, name: "数字出版" },
            { id: 999, name: "其他" }
        ]
    },
    {
        id: 6,
        name: "历史学",
        minors: [
            { id: 61, name: "历史学" },
            { id: 62, name: "世界史" },
            { id: 63, name: "考古学" },
            { id: 64, name: "文物与博物馆学" },
            { id: 65, name: "文化遗产" },
            { id: 66, name: "历史建筑保护工程" },
            { id: 999, name: "其他" }
        ]
    },
    {
        id: 7,
        name: "理学",
        minors: [
            { id: 71, name: "数学与应用数学" },
            { id: 72, name: "信息与计算科学" },
            { id: 73, name: "物理学" },
            { id: 74, name: "应用物理学" },
            { id: 75, name: "化学" },
            { id: 76, name: "应用化学" },
            { id: 77, name: "生物科学" },
            { id: 78, name: "生物技术" },
            { id: 79, name: "生态学" },
            { id: 80, name: "统计学" },
            { id: 81, name: "地理科学" },
            { id: 82, name: "自然地理与资源环境" },
            { id: 83, name: "人文地理与城乡规划" },
            { id: 84, name: "地理信息科学" },
            { id: 85, name: "大气科学" },
            { id: 86, name: "应用气象学" },
            { id: 87, name: "海洋科学" },
            { id: 88, name: "海洋技术" },
            { id: 89, name: "地球物理学" },
            { id: 90, name: "空间科学与技术" },
            { id: 999, name: "其他" }
        ]
    },
    {
        id: 8,
        name: "工学",
        minors: [
            { id: 81, name: "理论与应用力学" },
            { id: 82, name: "工程力学" },
            { id: 83, name: "机械工程" },
            { id: 84, name: "机械设计制造及其自动化" },
            { id: 85, name: "材料成型及控制工程" },
            { id: 86, name: "机械电子工程" },
            { id: 87, name: "工业设计" },
            { id: 88, name: "过程装备与控制工程" },
            { id: 89, name: "车辆工程" },
            { id: 90, name: "汽车服务工程" },
            { id: 91, name: "测控技术与仪器" },
            { id: 92, name: "材料科学与工程" },
            { id: 93, name: "材料物理" },
            { id: 94, name: "材料化学" },
            { id: 95, name: "冶金工程" },
            { id: 96, name: "金属材料工程" },
            { id: 97, name: "无机非金属材料工程" },
            { id: 98, name: "高分子材料与工程" },
            { id: 99, name: "复合材料与工程" },
            { id: 100, name: "能源与动力工程" },
            { id: 101, name: "电气工程及其自动化" },
            { id: 102, name: "电子信息工程" },
            { id: 103, name: "电子科学与技术" },
            { id: 104, name: "通信工程" },
            { id: 105, name: "微电子科学与工程" },
            { id: 106, name: "光电信息科学与工程" },
            { id: 107, name: "信息工程" },
            { id: 108, name: "自动化" },
            { id: 109, name: "计算机科学与技术" },
            { id: 110, name: "软件工程" },
            { id: 111, name: "网络工程" },
            { id: 112, name: "信息安全" },
            { id: 113, name: "物联网工程" },
            { id: 114, name: "数字媒体技术" },
            { id: 115, name: "土木工程" },
            { id: 116, name: "建筑环境与能源应用工程" },
            { id: 117, name: "给排水科学与工程" },
            { id: 118, name: "建筑电气与智能化" },
            { id: 119, name: "城市地下空间工程" },
            { id: 120, name: "道路桥梁与渡河工程" },
            { id: 121, name: "水利水电工程" },
            { id: 122, name: "水文与水资源工程" },
            { id: 123, name: "港口航道与海岸工程" },
            { id: 124, name: "测绘工程" },
            { id: 125, name: "遥感科学与技术" },
            { id: 126, name: "地理空间信息工程" },
            { id: 127, name: "化学工程与工艺" },
            { id: 128, name: "制药工程" },
            { id: 129, name: "资源循环科学与工程" },
            { id: 130, name: "能源服务工程" },
            { id: 131, name: "环境工程" },
            { id: 132, name: "环境科学" },
            { id: 133, name: "环境生态工程" },
            { id: 134, name: "环保设备工程" },
            { id: 135, name: "资源环境科学" },
            { id: 136, name: "生物医学工程" },
            { id: 137, name: "食品科学与工程" },
            { id: 138, name: "食品质量与安全" },
            { id: 139, name: "粮食工程" },
            { id: 140, name: "乳品工程" },
            { id: 141, name: "酿酒工程" },
            { id: 142, name: "葡萄与葡萄酒工程" },
            { id: 143, name: "食品营养与检验教育" },
            { id: 144, name: "烹饪与营养教育" },
            { id: 145, name: "建筑类" },
            { id: 146, name: "安全工程" },
            { id: 147, name: "生物工程" },
            { id: 148, name: "刑事科学技术" },
            { id: 149, name: "消防工程" },
            { id: 150, name: "交通工程" },
            { id: 151, name: "交通运输" },
            { id: 152, name: "交通设备与控制工程" },
            { id: 153, name: "救助与打捞工程" },
            { id: 154, name: "船舶电子电气工程" },
            { id: 155, name: "海洋工程与技术" },
            { id: 156, name: "海洋资源开发技术" },
            { id: 157, name: "兵器类" },
            { id: 158, name: "核工程与核技术" },
            { id: 159, name: "辐射防护与核安全" },
            { id: 160, name: "工程物理" },
            { id: 161, name: "农业工程类" },
            { id: 162, name: "林业工程类" },
            { id: 163, name: "公安技术类" },
            { id: 999, name: "其他" }
        ]
    },
    {
        id: 9,
        name: "农学",
        minors: [
            { id: 91, name: "农学" },
            { id: 92, name: "园艺" },
            { id: 93, name: "植物保护" },
            { id: 94, name: "植物科学与技术" },
            { id: 95, name: "种子科学与工程" },
            { id: 96, name: "设施农业科学与工程" },
            { id: 97, name: "茶学" },
            { id: 98, name: "烟草" },
            { id: 99, name: "应用生物科学" },
            { id: 100, name: "农艺教育" },
            { id: 101, name: "园艺教育" },
            { id: 102, name: "智慧农业" },
            { id: 103, name: "农业资源与环境" },
            { id: 104, name: "野生动物与自然保护区管理" },
            { id: 105, name: "水土保持与荒漠化防治" },
            { id: 106, name: "动物科学" },
            { id: 107, name: "蚕学" },
            { id: 108, name: "蜂学" },
            { id: 109, name: "动物医学" },
            { id: 110, name: "动物药学" },
            { id: 111, name: "动植物检疫" },
            { id: 112, name: "实验动物学" },
            { id: 113, name: "中兽医学" },
            { id: 114, name: "林学" },
            { id: 115, name: "园林" },
            { id: 116, name: "森林保护" },
            { id: 117, name: "经济林" },
            { id: 118, name: "智慧林业" },
            { id: 119, name: "水产养殖学" },
            { id: 120, name: "海洋渔业科学与技术" },
            { id: 121, name: "水族科学与技术" },
            { id: 122, name: "水生动物医学" },
            { id: 123, name: "渔业资源与渔政管理" },
            { id: 999, name: "其他" }
        ]
    },
    {
        id: 10,
        name: "医学",
        minors: [
            { id: 101, name: "基础医学" },
            { id: 102, name: "临床医学" },
            { id: 103, name: "麻醉学" },
            { id: 104, name: "医学影像学" },
            { id: 105, name: "眼视光医学" },
            { id: 106, name: "精神医学" },
            { id: 107, name: "放射医学" },
            { id: 108, name: "口腔医学" },
            { id: 109, name: "预防医学" },
            { id: 110, name: "食品卫生与营养学" },
            { id: 111, name: "中医学" },
            { id: 112, name: "针灸推拿学" },
            { id: 999, name: "其他"}
        ]
    }
      ],

        fieldlist: [
            // 动态字段列表
        ],
        formlist: {},
        uploadUrl:'',
        defaultUrl:'',
        rules: {
        realname: [
        { required: true, message: '请输入用户名', trigger: 'blur' },
        { required: true, message: '请正确输入用户名，必须2-4位中文', trigger: 'blur',pattern: /^[\u4E00-\u9FA5]{2,4}$/}
      ],
},minorCategories:''
    }
  },
  mounted(){
     this.fetchDetail(this.id);
     this.getbnotes()
     this.uploadUrl = baseURL + '/api/bnotes/upload';
     this.defaultUrl='/image-1.png'
  },
   created() {
    // 初始化formData
    this.fieldlist.forEach(field => {
      this.$set(this.formData, field.id, '');
    });
  },
  computed: {
    uploadRelatedValues() {
            const currentFieldIndex = this.fieldlist.findIndex(field => field.type === 'pic' && field.file === this.currentUploadFile);
            const currentFieldId = this.fieldlist[currentFieldIndex].id;
            const currentFieldValue = this.formData[currentFieldId];

            return {
                fieldId: currentFieldId,
                fieldValue: currentFieldValue,
                fieldType: this.fieldlist[currentFieldIndex].type
            };
        }
},
  methods: {
    loadMinorCategories() {
      const subject_type = this.majorCategories.find(
        category => category.name == this.formData.subject_type
      );
      if (subject_type) {
        console.log(subject_type.minors)
        this.minorCategories = subject_type.minors;
      } else {
        this.minorCategories = [];
      }
    },
    getbnotes(){
      axios.post(baseURL + '/api/bnotes/status',{},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}` // 从 localStorage 中获取 token
        }}).then((res)=>{
          if (res.data.status==1) {
            this.formData = res.data.data;
            const subject_type = this.majorCategories.find(
        category => category.name == this.formData.subject_type
      );
      if (subject_type) {
        console.log(subject_type.minors)
        this.minorCategories = subject_type.minors;
      } else {
        this.minorCategories = [];
      }
        }else if(res.data.code==503){
          this.$router.push('/')
        }
        })
    },
    validatePhone(rule, value, callback) {
        if (!value) {
          callback(new Error('手机号不能为空'));
        } else if (!/^1[3-9]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      },
      validateidcard2(rule, value, callback) {
        if (value=='/image-1.png') {
          callback(new Error('身份证反面不能为空'));
        }
      },
      validategrad(rule, value, callback) {
        if (value=='/image-1.png') {
          callback(new Error('身份证反面不能为空'));
        }
      },
      validateidcard(rule, value, callback) {
        if (value=='/image-1.png') {
          callback(new Error('身份证反面不能为空'));
        }
      },
      // 
      validateEdu(rule, value, callback){
        if (!value) {
          callback(new Error('请选择教育经历'));
        } 
      },
      validatesubjectType(rule, value, callback){
        if (!value) {
          callback(new Error('请选择专业名称'));
        } 
      },
    validatefieldname(rule,value,callback){
      console.log(value)
      if (!value) {
          callback(new Error('值不能为空'));
        } 
    },
      validatesubject(rule, value, callback){
        if (!value) {
          callback(new Error('请输入专业'));
        } 
      },
      validateschool(rule, value, callback){
        if (!value) {
          callback(new Error('请输入毕业院校'));
        } 
      },
      validatebirthday(rule, value, callback){
        if (!value) {
          callback(new Error('请选择出生日期'));
        } 
      },
      validateAvatar(rule, value, callback) {
        if (!value) {
          callback(new Error('头像不能为空'));
        }
      },
      validateRealname(rule, value, callback) {
        if (!value) {
          callback(new Error('姓名不能为空'));
        } else if (!/^[\u4E00-\u9FA5]{2,4}$/) {
          callback(new Error('请输入2-4位中文姓名'));
        } else {
          callback();
        }
      },
      validateSex(rule, value, callback) {
        if (!value) {
          callback(new Error('请选择性别'));
        } 
      },
      validatePhone(rule, value, callback) {
        if (!value) {
          callback(new Error('手机号不能为空'));
        } else if (!/^1[3-9]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      },
      validatePhone(rule, value, callback) {
        if (!value) {
          callback(new Error('手机号不能为空'));
        } else if (!/^1[3-9]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      },
    handleSuccess(response) {
        if (response && response.path) {
          
          this.formData.avatar = response.path; // 假设后端返回的响应中包含头像的 URL
          this.$message.success('上传成功!');
        } else {
          this.$message.error('上传失败，请重试!');
        }
      },
       // 上传前的验证
       beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
  
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      // 上传成功的回调
      handleAvatarSuccess(response) {
        if (response && response.path) {
          this.formData.avatar = response.path; // 假设后端返回的响应中包含头像的 URL
          this.$message.success('上传成功!');
        } else {
          this.$message.error('上传失败，请重试!');
        }
      },
    handleclick()
      {
        const inputvalue=this.$refs.uploadRef.$el.querySelector('input[type="file"]')
        if(inputvalue){
            inputvalue.click();
        }
      },
    httpRequests2(option){
        const formData = new FormData();
         // 获取当前正在上传图片对应的formData[field.id]的值（假设fieldlist和formData的对应关系是按顺序匹配的，实际可能需要更精准的获取方式）
         const { fieldId} = this.uploadRelatedValues;
        console.log(fieldId)

        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          if(this.formData[fieldId]){
            this.formData[fieldId].push(response.data.path)
            console.log(response.data.path); // 成功回调
          }else{
            this.formData[fieldId]=[response.data.path];
          }
       
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      httpRequests(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.formData.avatar=response.data.path
        
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      httpRequestidcard(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.formData.idcard=response.data.path
        
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      httpRequestidcard2(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.formData.idcard2=response.data.path
        
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      httpRequestgrad(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.formData.grad=response.data.path
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
   // 添加一个方法来格式化日期
   formattedDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const day = d.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    fetchDetail(id) {
        var that=this;
        axios
        .post(baseURL+'/api/fieldlist/getfield', {id:id},{headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
      }}) //
        .then((response) => {
          this.fieldlist= response.data; // 假设后端返回的是项目列表
          if(this.fieldlist){
            this.fieldlist.forEach((data)=>{
                that.formlist=data;
                console.log(data)
            })
          }
        })
        .catch((error) => {
          console.error('错误:', error);
        });  
      },
    validateNumber(rule, value) {
      const numberRegex = /^\d+$/;
      if (!value) {
        //this.$message.error('请输入数字')
      } else if (!numberRegex.test(value)) {
        this.$message.error('请输入正确的数字格式')
      }
    },
      submitForm()
      {
          axios.post(baseURL+'/api/report/setreport',{formData:this.formData,eid:this.id},{headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
        }}).then((res)=>{
               if(res.data.code==200){
                  this.$message.success(res.data.msg)
                  this.$router.push('/ApplyInfo')
               }else{
                  this.$message.error(res.data.msg)
               }
          })
  },
  beforeUpload(file) {
                    const isPNGorJPG = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg';
                    if (!isPNGorJPG) {
                        this.$message.error('请上传PNG或JPG格式的文件');
                        return false;
                    }
                    return true;
                },
                handleSuccess(response, file, fileList) {
                  console.log(response)
                    console.log(fileList)
                    console.log(file)
                    this.$message.success('文件上传成功');
                }

              }
            }
</script>
<style>
.el-form-item__label {
  white-space: nowrap;
}
.avatar-uploader {
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  avatar-uploader2{
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    cursor: pointer;
    position: relative;
  }
  .avatar2 {
    width: 100px;
    height: 100px;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .submitted-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 10px;
  }
.basic-title{
  color:#87CEEB;
}
.avatar-uploader-wrapper {
    border: 1px solid #000; /* 这里设置边框样式，可根据需要调整颜色、宽度等 */
    display: inline-block; /* 保持元素为内联块级元素，以便与其他元素正常排列 */
}
.bottom{
  margin-bottom:5px;
}
.elcol2{
  padding-left: 5rem;
}
</style>