<template>
    <div class="login-container">
    <el-card class="backelcard">
      <div class="login-card2">
        <!-- 新增滚动新闻 -->
        <div class="news-container">
          <div class="news-marquee">
            <div 
              v-for="(news, index) in newsList" 
              :key="index" 
              class="news-item"
              @click="showNewsDetail(news)"
            >
              {{ news.title }}
            </div>
          </div>
        </div>
      </div>
    </el-card>
      <!-- 右侧表单 -->
      <el-card class="login-card">
        <h1>招考系统登录</h1>
  
        <!-- 标签按钮切换 -->
        <div class="tab-buttons">
          <el-button :type="activeTab === 'account' ? 'primary' : 'default'" class="full-width" @click="getactiveTab('account')" activeTab = 'account'>账号密码登录</el-button>
          <el-button :type="activeTab === 'code' ? 'primary' : 'default'" class="full-width" @click="getactiveTab('code')">验证码登录</el-button>
        </div>
  
        <!-- 账号密码登录表单 -->
        <el-form v-if="activeTab === 'account'" @submit.prevent="login" :model="loginForm" ref="loginForm" label-width="80px">
          <el-form-item label="用户名" prop="username" :rules="[{ required: true, message: '请输入用户名', trigger: 'blur' }]">
            <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]">
            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="vcode" :rules="[{ required: true, message: '请输入验证码', trigger: 'blur' }]">
            <el-input class="el-input1" v-model="loginForm.vcode" placeholder="请输入验证码"></el-input>
            <img :src="captcha" :key="captcha" class="captcha" @click="getCaptcha" ref="captcha"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="full-width1 submit-button1" @click="login">立即登录</el-button>
          </el-form-item>
        </el-form>
  
        <!-- 验证码登录表单 -->
        <el-form v-if="activeTab === 'code'" @submit.prevent="login" :model="loginForm" ref="loginForm" label-width="80px">
          <el-form-item label="手机号" prop="phone" :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' }]">
            <el-input v-model="loginForm.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
           <!-- 获取验证码按钮，当手机号正确时才显示 -->
           <el-form-item>
            <el-button type="primary" @click="getvCode" :disabled="isButtonDisabled">
              {{ buttonLabel }}
            </el-button>
          </el-form-item>
           <!-- 验证码输入框 -->
           <el-form-item label="验证码" v-if="showvCode" prop="logincode" :rules="[{ required: true, message: '请输入验证码', trigger: 'blur' }]">
            <el-input v-model="loginForm.logincode" placeholder="请输入验证码"></el-input>
          </el-form-item>
          <el-form-item>
            <input type="hidden" @keyup.enter="login"/>
            <el-button type="primary" class="full-width1 submit-button1" @click="login">立即登录</el-button>
          </el-form-item>
        </el-form>
  
        <!-- 注册按钮与登录按钮对齐 -->
        <el-form-item class="register-button">
          <router-link to="/register">
            <el-button type="default" class="full-width2 submit-button2">没有账号？立即注册</el-button>
          </router-link>
        </el-form-item>
      </el-card>
    </div>
      <!-- 新闻详情弹窗 -->
      <el-dialog 
      v-model="dialogVisible" 
      title="新闻详情" 
      width="40%"
      center
    >
      <h3 class="news-title">{{ currentNews.title }}</h3>
      <div class="news-content" v-html="currentNews.content"></div>
      <template #footer>
        <el-button @click="dialogVisible = false">关闭</el-button>
      </template>
    </el-dialog>
  </template>
  
  <script>
  import axios from 'axios';
  import { ElMessage } from 'element-plus';
  import {baseURL} from '@/api/base.js';
  export default {
    data() {
      return {
        activeTab: 'account', // 当前选中的标签
        loginForm: {
          username: '',
          password: '',
          phone: '',
          vcode:'',
          logincode:'',
          apiurl:baseURL,
          captcha:''
        },
        showvCode: false,
        isButtonDisabled: false,
        buttonLabel: '获取验证码',
        countdown: 60,
        newsList: [
        {
          id:1,
          title: '【重要通知】2024年度招生考试时间安排公布',
          content: '2024年度招生考试将于3月15日正式开始报名，详细时间表请查看公告...'
        },
        {
          id:2,
          title: '系统维护公告：2月28日0点至6点系统升级',
          content: '为提升服务质量，系统将于2月28日进行维护升级，期间暂停服务...'
        },
        {
          id:3,
          title: '考生须知：考试注意事项及考场规则',
          content: '请各位考生提前30分钟到达考场，携带有效身份证件及准考证...'
        }
        ],
        currentNews: {},
        dialogVisible: false
      }
    },
    created(){
       if(localStorage.getItem('token')){
         this.$router.push('/')
       }
       var _this = this;
    document.onkeydown = function() {   //按下回车提交
        let key = window.event.keyCode;  
        //事件中keycode=13为回车事件
        if (key == 13) {
            _this.login();
        }
    };
    },
    mounted(){
      this.getCaptcha();
      this.getNews()
    },
    methods: {
      showNewsDetail(news) {
      this.currentNews = news;
      this.dialogVisible = true;
    },
      getactiveTab(data){
        this.activeTab=data;
        if(data=='account'){
           this.getCaptcha()
        }
      },
      getNews(){
        // const timestamp = new Date().getTime(); 
  const newCaptchaUrl = baseURL+`/api/login/getnews`;
  axios.get(newCaptchaUrl, {})
          .then((response) => {
            this.newsList=response.data.data
          })
          .catch(() => {
             
          });
      },
      getCaptcha(){
        // const timestamp = new Date().getTime(); 
  const newCaptchaUrl = baseURL+`/api/login/captcha`;
  this.captcha = newCaptchaUrl;
  axios.get(newCaptchaUrl, {})
          .then((response) => {
             // 手动更新 <img> 元素的 src，避免 Vue 内部渲染机制的问题
        if (this.$refs.captcha) {
          this.$refs.captcha.src = response.data.image;
          localStorage.setItem('uniqid',response.data.uniqid)
        }
          })
          .catch(() => {
             
          });
      },
      login() {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            let loginData;
            if (this.activeTab === 'account') {
              // 账号密码登录
              loginData = { username: this.loginForm.username, password: this.loginForm.password,vcode:this.loginForm.vcode,uniqid:localStorage.getItem('uniqid') };
            } else {
              // 验证码登录
              loginData = {phone: this.loginForm.phone, code: this.loginForm.logincode };
            }
  
            axios.post(baseURL+'/api/login', loginData)
              .then(response => {
                if(response.data.token){
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('username', response.data.username);
                    this.$router.push('/');
                }else{
                    ElMessage.error(response.data.msg);
                }
              })
          } else {
            ElMessage.error('请正确填写表单');
          }
        });
      },
      getvCode() {
        if (this.isPhoneValid) {
          axios.post(baseURL+'/api/sendcode/login', { phone: this.loginForm.phone })
            .then((res) => {
             if(res.data.data.success){
                ElMessage.success('验证码发送成功！');
              this.showvCode = true;
              this.startCountdown();
             }else{
                ElMessage.error('验证码发送失败');
             }
            })
            .catch(() => {
              ElMessage.error('验证码发送失败');
            });
        } else {
          ElMessage.error('请输入有效的手机验证码');
        }
      },
      startCountdown() {
        this.isButtonDisabled = true;
        this.buttonLabel = `请等待${this.countdown} 秒`;
  
        const timer = setInterval(() => {
          this.countdown--;
          if (this.countdown > 0) {
            this.buttonLabel = `请等待 ${this.countdown} 秒`;
          } else {
            clearInterval(timer);
            this.buttonLabel = '重新获取';
            this.isButtonDisabled = false;
            this.countdown = 60;
          }
        }, 1000);
      }
    },
    computed: {
      isPhoneValid() {
        const phoneRegex = /^[1][3-9][0-9]{9}$/;
        return phoneRegex.test(this.loginForm.phone);
      }
    }
  };
  </script>
  
  <style scoped>
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ADD8E6; /* 替换为实际图片路径 */
    background-size: cover;
    background-position: center;
  }
  .captcha{
    display: inline-block;
    height: 35px;
    width:90px;
    cursor: pointer;
  }
  .el-input1{
    width:10rem;
    display: inline-block;
  }
  .login-card {
    width: 26rem;
    padding: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  }
  .login-card2{
    width: 23.7rem;
    height:26.7rem;
  }
  .tab-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .full-width {
    width: 100%;
  }
  .full-width1 {
    width: 70%;
    margin-left:-100px;
  }
  .full-width2 {
    width: 100%;
    margin-left:20px;
  }
  
  .el-form-item {
    margin-bottom: 20px;
  }
  
  /* 调整注册按钮与登录按钮对齐 */
  .register-button {
    margin-top: -10px; /* 调整注册按钮的位置，使其与“立即登录”按钮上下对齐 */
  }
  
  .submit-button {
    display: block;
    width: 100%;
  }
  .submit-button1 {
    display: block;
    width: 23.7rem;
    margin-left:-5rem;
  }
  .submit-button2{
    width:23.6rem;
    margin-left:-0.2rem;
  }
  .news-container {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.news-marquee {
  position: absolute;
  width: 100%;
  padding: 20px;
  animation: scroll 25s linear infinite;
  max-height: 10rem;
}

.news-marquee:hover {
  animation-play-state: paused;
}

.news-item {
  padding: 10px 0;
  color: #2c3e50;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px dashed #eee;
}

.news-item:hover {
  color: #409eff;
  background-color: #f5f7fa;
}

.news-title {
  color: #303133;
  margin-bottom: 15px;
  text-align: center;
}

.news-content {
  max-height: 400px; /* 设置最大高度 */
  overflow-y: auto; /* 启用垂直滚动条 */
  padding: 10px; /* 可选：添加一些内边距 */
  border: 1px solid #ddd; /* 可选：添加边框 */
  border-radius: 4px; /* 可选：添加圆角 */
}

@keyframes scroll {
  0% { transform: translateY(100%); }
  100% { transform: translateY(-100%); }
}
.news-img{
  width: 100%;
  height: 100%;
}
.gonggao{
  font-size: 30px;
  font-weight: bold;
  position: relative;
  left:35%;
}
.backelcard{
  background-image: url('../images/background.png');
  background-repeat: no-repeat;
  background-size: 125%;
}
  </style>
  