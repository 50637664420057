<template>
  <div id="app">
    <div v-show="isMobile" class="mobile-tip">
      <h2>请使用PC端访问本系统</h2>
      <p>当前页面不支持移动端显示</p>
    </div>
    <div v-show="!isMobile">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { isMobile: false }
  },
  mounted(){
    this.checkDevice()
  },
  methods: {
    checkDevice() {
  const ua = navigator.userAgent.toLowerCase();
  // 扩展正则检测关键词 ‌:ml-citation{ref="1,2" data="citationList"}
  const mobileKeywords = /android|iphone|ipad|ipod|mobi|blackberry|webos|iemobile|harmony|windows phone|opera mini|kindle|silk/;
  // 增加触控设备检测逻辑 ‌:ml-citation{ref="2,6" data="citationList"}
  const isTouchDevice = navigator.maxTouchPoints > 1 && !/windows|linux/.test(ua);
  
  this.isMobile = mobileKeywords.test(ua) || isTouchDevice;
}

  }
}

</script>
<script setup>
const debounce = (fn, delay) => {
 let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
</script>
<style>
.mobile-tip {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
  text-align: center;
  padding: 20%;
}
  /* 添加一些全局样式，或者保持空白 */
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  el-container{
    padding: 0 0 0 0;
  }
  .scroll{
            overflow: scroll;
}
        .scroll::-webkit-scrollbar{
            display: none;
        }
        h2{
          padding: 10px;
        }
</style>
  