<template>
  <div class="company-list">
    <el-tabs v-model="activeTab" @tab-click="handleTabClick" class="mytabs">
      <el-tab-pane name="1" label="所有项目" class="mytabs">
      <template v-if="zk">
      <div class="comp">
      <div class="job-card-vertical" v-for="(company, index) in zk" :key="index">
          <div class="company-info">
            <span class="company-name">{{ company.cname }}</span>
            <!-- <span class="job-title">考试场次：<span class="scolor">{{ company.zname }}</span></span> -->
          </div>
          <ul class="vertical-list">
            <li>
              <span class="list-label">考试场次：</span>
              <span class="list-value"><span class="scolor">{{ company.zname }}</span></span>
            </li>
            <li>
              <span class="list-label">学历要求：</span>
              <span class="list-value"><span class="scolor">{{ company.edu }}</span></span>
            </li>  <li>
              <span class="list-label">经验要求：</span>
              <span class="list-value"><span class="scolor">{{ company.exp }}</span></span>
            </li>
            <li>
              <span class="list-label">报名时间：</span>
              <span class="list-value"><span class="scolor">{{ company.zbstarttime }}至{{ company.zbendtime }}</span></span>
            </li>
            <li>
              <span class="list-label">考试时间：</span>
              <span class="list-value"><span class="scolor">{{ company.examstarttime?company.examstarttime:'待定'}}</span></span>
            </li>
              <li class="jbrief">
              {{company.jbrief}}
              </li>
            <li class="caozuo">
          <el-button 
            type="primary" 
            @click="goToDetail(company.id)">
            查看详情
          </el-button> <el-button 
            type="danger" 
            @click="goToReport(company.id)"
          >
            立即报名
          </el-button>
        </li>
      </ul>
        <ui-card-footer>
          <!-- <span :class="getStatusClass(company.status)">{{ company.status }}</span> -->
        </ui-card-footer>
      </div>
    </div>
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 50]"
      :page-size="pageSize"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper" style="margin-left:30%;margin-top:10px;">
    </el-pagination>
    </template>
    <template v-else>
      <el-alert title="暂无招考数据" type="info" show-icon />
    </template>
    </el-tab-pane>
    <el-tab-pane name="2" label="进行中" class="mytabs">
      <template v-if="zk">
      <div class="comp">
      <div class="job-card-vertical" v-for="(company, index) in zk" :key="index">
          <div class="company-info">
            <span class="company-name">{{ company.cname }}</span>
            <!-- <span class="job-title">考试场次：<span class="scolor">{{ company.zname }}</span></span> -->
          </div>
          <ul class="vertical-list">
             <li>
              <span class="list-label">考试场次：</span>
              <span class="list-value"><span class="scolor">{{ company.zname }}</span></span>
            </li>
            <li>
              <span class="list-label">学历要求：</span>
              <span class="list-value"><span class="scolor">{{ company.edu }}</span></span>
            </li>  <li>
              <span class="list-label">经验要求：</span>
              <span class="list-value"><span class="scolor">{{ company.exp }}</span></span>
            </li>
            <li>
              <span class="list-label">报名时间：</span>
              <span class="list-value"><span class="scolor">{{ company.zbstarttime }}至{{ company.zbendtime }}</span></span>
            </li>
            <li>
              <span class="list-label">考试时间：</span>
              <span class="list-value"><span class="scolor">{{ company.examstarttime?company.examstarttime:'待定'}}</span></span>
            </li>
              <li class="jbrief">
              {{company.jbrief}}
              </li>
            <li class="caozuo">
          <el-button 
            type="primary" 
            @click="goToDetail(company.id)">
            查看详情
          </el-button> <el-button 
            type="danger" 
            @click="goToReport(company.id)"
          >
            立即报名
          </el-button>
        </li>
      </ul>
        <ui-card-footer>
          <!-- <span :class="getStatusClass(company.status)">{{ company.status }}</span> -->
        </ui-card-footer>
      </div>
    </div>
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 50]"
      :page-size="pageSize"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper" style="margin-left:30%;margin-top:10px;">
    </el-pagination>
    </template>
    <template v-else>
      <el-alert title="暂无招考数据" type="info" show-icon />
    </template>
    </el-tab-pane>
    <el-tab-pane name="3" label="已结束" class="mytabs">
      <template v-if="zk">
      <div class="comp">
      <div class="job-card-vertical" v-for="(company, index) in zk" :key="index">
          <div class="company-info">
            <span class="company-name">{{ company.cname }}</span>
            <!-- <span class="job-title">考试场次：<span class="scolor">{{ company.zname }}</span></span> -->
          </div>
          <ul class="vertical-list">
            <li>
              <span class="list-label">考试场次：</span>
              <span class="list-value"><span class="scolor">{{ company.zname }}</span></span>
            </li>
            <li>
              <span class="list-label">学历要求：</span>
              <span class="list-value"><span class="scolor">{{ company.edu }}</span></span>
            </li>  <li>
              <span class="list-label">经验要求：</span>
              <span class="list-value"><span class="scolor">{{ company.exp }}</span></span>
            </li>
            <li>
              <span class="list-label">报名时间：</span>
              <span class="list-value"><span class="scolor">{{ company.zbstarttime }}至{{ company.zbendtime }}</span></span>
            </li>
            <li>
              <span class="list-label">考试时间：</span>
              <span class="list-value"><span class="scolor">{{ company.examstarttime?company.examstarttime:'待定'}}</span></span>
            </li>
              <li class="jbrief">
              {{company.jbrief}}
              </li>
            <li class="caozuo">
          <el-button 
            type="primary" 
            @click="goToDetail(company.id)">
            查看详情
          </el-button> <el-button 
            type="danger" 
            @click="goToReport(company.id)"
          >
            立即报名
          </el-button>
        </li>
      </ul>
        <ui-card-footer>
          <!-- <span :class="getStatusClass(company.status)">{{ company.status }}</span> -->
        </ui-card-footer>
      </div>
    </div>
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 50]"
      :page-size="pageSize"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper" style="margin-left:30%;margin-top:10px;">
    </el-pagination>
    </template>
    <template v-else>
      <el-alert title="暂无招考数据" type="info" show-icon />
    </template>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import axios from 'axios';
import { baseURL } from '@/api/base.js';
import sanitizeHtml from 'sanitize-html';
export default {
  data() {
    return {
      zk: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      rawContent:'',
      safeContent:'',
      activeTab:1
    };
  },
  mounted() {
    this.activeTab = '1';
    this.currentPage = 1;
    this.$nextTick(()=>{
      this.getzk();     
    })
  },
  created() {
    // 使用 sanitize-html 解析并清理富文本内容
    this.safeContent = sanitizeHtml(this.rawContent, {
      allowedTags: ['p', 'strong', 'em', 'a', 'ul', 'ol', 'li', 'br'], // 允许的 HTML 标签
      allowedAttributes: {
        'a': ['href', 'target'] // 允许的属性，如 a 标签的 href 和 target
      },
      allowedSchemes: ['http', 'https', 'mailto'] // 允许的 URL 协议
    });
  },
  methods: {
    async handleTabClick(tab) {
    this.activeTab = tab.name;
    this.currentPage = 1;
    await this.$nextTick();
    this.getzk(this.activeTab);
},
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getzk();
    },
    // 当当前页面改变时触发
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getzk();
    },
    goToDetail(id) {
      this.$router.push({ name: 'ApplyDetail', params: { id } });
    },
    sanitizeHtml(content) {
      return sanitizeHtml(content, {
        allowedTags: ['p', 'strong', 'em', 'a', 'ul', 'ol', 'li', 'br'],
        allowedAttributes: {
          'a': ['href', 'target']
        },
        allowedSchemes: ['http', 'https', 'mailto']
      });
    },
    getzk(tab=1) {
      console.log(tab)
      axios
     .post(baseURL+'/api/zk/getzk', {currentPage:this.currentPage,pageSize:this.pageSize,tab:tab},{headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
        }})
     .then((response) => {
          this.zk = response.data.data; // 假设后端返回的是项目列表
          this.total=response.data.total;
        })
     .catch((error) => {
          console.error('错误:', error);
        });
    },
    // 去除 HTML 标签，保留纯文本
    stripHtml(html) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.innerText;
    },
    truncatedBrief(brief) {
      let htmlContent = brief;
      let tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlContent;
      let textContent = tempDiv.innerText || tempDiv.textContent;

      return textContent
    },
    handleClick(company){
      this.$router.push({ name: 'ApplyDetail', params: {company }});
    },
    goToReport(id){
      if(this.disabled){
        alert('未到报名时间')
      }else{
        axios.post(baseURL+'/api/zk/getuidzk',{id:id},{headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
      }}).then((response)=>{
         if(!response.data.success){
            if(response.data.code==503){
              window.location.reload()
            }
            this.$message.error(response.data.msg)
         }else{
           this.$router.push('/setReport/'+id)
         }
      })
      }
    }
  }
};
</script>
<style>
/* 公司列表的整体样式 */
.company-list {
    /* 使用flex布局，实现灵活排列 */
    display: flex;
    /* 允许元素在排不下时换行 */
    flex-wrap: wrap;
    margin: 20px;
    /* 设置为主轴方向为水平方向，即横向排列 */
    flex-direction: row;
    /* 交叉轴方向（垂直方向）上顶部对齐 */
    align-items: flex-start;
}

/* 公司卡片的样式，这里保持原有的大部分设置，可根据实际需求进一步调整 */
.company-card {
    width: calc(45% - 20px);
    height: 500px;
    padding: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}

.company-name {
    font-weight: bold;
}

.content-wrapper {
    padding: 10px 0;
}

.status-row {
    text-align: center;
}

.status-tag {
    font-size: 16px;
}

.el-icon-check {
    color: green;
}

.el-icon-close-circle {
    color: gray;
}

.job-list-ui-vertical {
    list-style-type: none;
    padding: 0;
    margin: 0;
    /* 这里原先是纵向排列，现在先保持原设置，可根据实际需求决定是否也改为横向排列 */
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 45%;
}

/* 职位卡片的样式，设置为横向排列 */
.job-card-vertical {
  width: 48.9%;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-left:30px;
}

/* 卡片头部的样式，设置为横向排列 */
.ui-card-header {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    /* 使用flex布局并设置为主轴方向为横向 */
    display: flex;
    flex-direction: row;
}

.company-info {
    display: flex;
    flex-direction: column;
}

.company-name {
    font-size: 20px;
    color: #333;
    background-color: #F0F0F0;
    line-height: 30px;
}

.job-title {
    font-size: 16px;
    color: #666;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #ccc;
    line-height: 30px;
    text-align: left;
}

/* 卡片内容的样式，设置为横向排列 */
.ui-card-content {
    padding: 15px;
    /* 使用flex布局并设置为主轴方向为横向 */
    display: flex;
    flex-direction: row;
}

/* 垂直列表的样式，设置为横向排列且排不下换行 */
.vertical-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    /* 使用flex布局并设置为主轴方向为横向 */
    display: flex;
    flex-direction: row;
    /* 允许元素在排不下时换行 */
    flex-wrap: wrap;
    width: 100%;
}

/* 垂直列表项的样式，这里可根据实际需求进一步设置每个列表项的宽度等属性 */
.vertical-list li {
    border-top: 1px solid #ccc;
    text-align: left;
    list-style: none;
    line-height: 3rem;
}
.caozuo{
  border-top:0px !important;
  margin-left:30%;
}
.list-label {
    font-size: 16px;
    color: #666;
    font-weight: bold;
    margin-bottom: 3px;
    padding-left: 5px;
}

.list-value {
    font-size: 16px;
    color: #333;
    text-align: left;
}

.ui-card-actions {
    padding: 15px;
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* 卡片底部的样式，设置为横向排列 */
.ui-card-footer {
    padding: 15px;
    border-top: 1px solid #ccc;
    font-size: 16px;
    color: #666;
    /* 使用flex布局并设置为主轴方向为横向 */
    display: flex;
    flex-direction: row;
}
.mytabs{
  width: 100%;
}

.scolor {
    color: red;
}

.list-value2 {
    height: 300px;
}
.jbrief{
  height: 10rem;
  line-height:1.5rem !important;
  text-align: left;
  padding-right:2%;
}
.comp{
  width: 100%;
  display:flex;
}
</style>