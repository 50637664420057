<template>
  <div class="margins">

              <el-tabs v-model="activeTab" @tab-click="handleTabClick">
                  <el-tab-pane name="1" label="所有信息" class="mytabss">
    <template v-for="apply in applyinfo" :key="apply.id">
      <div class="job-card-vertical2">
        <div>
          <div class="company-info">
            <span class="company-name">{{ apply.cname }}</span>
            <!-- <span class="job-title">报考职位：<span class="scolor">{{ apply.zname }}</span></span> -->
          </div>
        </div>
        <div>
          <div class="vertical-list">
            <div>
              <span class="list-label">考试名称：</span>
              <span class="list-value"><span class="scolor">{{apply.ename}}</span></span>
            </div>
            <div>
              <span class="list-label">报名时间：</span>
              <span class="list-value"><span class="scolor">{{ apply.zbstarttime }}至{{ apply.zbendtime }}</span></span>
            </div>
            <div>
              <span class="list-label">考试时间：</span>
              <span class="list-value"><span class="scolor">{{ apply.examstarttime?apply.examstarttime:'待定'}}-{{apply.examendtime}}</span></span>
            </div>
            <div>
              <span class="list-text">{{apply.desc}}</span>
            </div>
            
            <div>
              <span class="list-label">状态：</span>
              <span class="list-value"><span class="scolor">{{ apply.estatus}}</span></span>
              </div>
            <div>
              <template v-if="apply.status==0||apply.status==4||apply.status==5">
          <el-button style="margin-left: 40%;">
            暂无操作
          </el-button>

        </template>
              <template v-if="apply.status==1">
          <el-button 
            type="primary" 
            @click="goToPay(apply.id)"
            class="uilist">
            支付
          </el-button>

        </template>
        <template v-if="apply.status==3">
          <el-button 
            type="success" 
            @click="goToDownLoad(apply.id)"
          >
            下载准考证
          </el-button>
        </template>
        </div>
          </div>
        </div>
        <div-footer>
          <!-- <span :class="getStatusClass(apply.status)">{{ apply.status }}</span> -->
        </div-footer>
      </div>
    </template>
                  </el-tab-pane>
                  <el-tab-pane name="2" label="流程中" class="mytabss">
    <template v-for="apply in applyinfo" :key="apply.id">
      <div class="job-card-vertical2">
        <div>
          <div class="company-info">
            <span class="company-name">{{ apply.cname }}</span>
            <!-- <span class="job-title">报考职位：<span class="scolor">{{ apply.zname }}</span></span> -->
          </div>
        </div>
        <div>
          <div class="vertical-list">
            <div>
              <span class="list-label">考试名称：</span>
              <span class="list-value"><span class="scolor">{{apply.ename}}</span></span>
            </div>
           
            <div>
              <span class="list-label">报名时间：</span>
              <span class="list-value"><span class="scolor">{{ apply.zbstarttime }}至{{ apply.zbendtime }}</span></span>
            </div>
            <div>
              <span class="list-label">考试时间：</span>
              <span class="list-value"><span class="scolor">{{ apply.examstarttime}}-{{apply.examendtime}}</span></span>
            </div>
            <div>
              <span class="list-text">{{apply.desc}}</span>
            </div>
            
            <div>
              <span class="list-label">状态：</span>
              <span class="list-value"><span class="scolor">{{ apply.estatus}}</span></span>
              </div>
            <div>
              <template v-if="apply.status==0||apply.status==4||apply.status==5">
          <el-button style="margin-left: 40%;">
            暂无操作
          </el-button>

        </template>
              <template v-if="apply.status==1">
          <el-button 
            type="primary" 
            @click="goToPay(apply.id)"
            class="uilist">
            支付
          </el-button>

        </template>
        <template v-if="apply.status==3">
          <el-button 
            type="success" 
            @click="goToDownLoad(apply.id)"
          >
            下载准考证
          </el-button>
        </template>
        </div>
          </div>
        </div>
        <div-footer>
          <!-- <span :class="getStatusClass(apply.status)">{{ apply.status }}</span> -->
        </div-footer>
      </div>
    </template>
                  </el-tab-pane>
                  <el-tab-pane name="3" label="已结束" class="mytabss">
    <template v-for="apply in applyinfo" :key="apply.id">
      <div class="job-card-vertical2">
        <div>
          <div class="company-info">
            <span class="company-name">{{ apply.cname }}</span>
            <!-- <span class="job-title">报考职位：<span class="scolor">{{ apply.zname }}</span></span> -->
          </div>
        </div>
        <div>
          <div class="vertical-list">
            <div>
              <span class="list-label">考试名称：</span>
              <span class="list-value"><span class="scolor">{{apply.ename}}</span></span>
            </div>
           
            <div>
              <span class="list-label">报名时间：</span>
              <span class="list-value"><span class="scolor">{{ apply.zbstarttime }}至{{ apply.zbendtime }}</span></span>
            </div>
            <div>
              <span class="list-label">考试时间：</span>
              <span class="list-value"><span class="scolor">{{ apply.examstarttime}}-{{apply.examendtime}}</span></span>
            </div>
            <div>
              <span class="list-text">{{apply.desc}}</span>
            </div>
            <div>
              <span class="list-label">状态：</span>
              <span class="list-value"><span class="scolor">{{ apply.estatus}}</span></span>
              </div>
            <div>
              <template v-if="apply.status==0||apply.status==4||apply.status==5">
          <el-button style="margin-left: 40%;">
            暂无操作
          </el-button>

        </template>
              <template v-if="apply.status==1">
          <el-button 
            type="primary" 
            @click="goToPay(apply.id)"
            class="uilist">
            支付
          </el-button>

        </template>
        <template v-if="apply.status==3">
          <el-button 
            type="success" 
            @click="goToDownLoad(apply.id)"
          >
            下载准考证
          </el-button>
        </template>
        </div>
          </div>
        </div>
        <div-footer>
          <!-- <span :class="getStatusClass(apply.status)">{{ apply.status }}</span> -->
        </div-footer>
      </div>
    </template>
                  </el-tab-pane>
              </el-tabs>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20, 50]"
              :page-size="pageSize"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper" style="margin-top: 30px;">
          </el-pagination>
     
  </div>
</template>
<script>
import axios from 'axios';
import { baseURL } from '@/api/base.js';

export default {
  data() {
      return {
          applyinfo: [],
          currentPage: 1,
          pageSize: 10,
          total: 0,
          activeTab: 1
      };
  },
  mounted() {
    this.activeTab = '1';
    this.currentPage = 1;
    this.$nextTick(()=>{
      this.getapplyinfo();      
    })
},
  methods: {
    async handleTabClick(tab) {
    this.activeTab = tab.name;
    this.currentPage = 1;
    await this.$nextTick();
    this.getapplyinfo(this.activeTab);
},
      async goToDownLoad(id) {
          try {
              axios({
                  url: baseURL + '/api/report/getdownload',
                  method: 'POST',
                  responseType: 'blob',
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`
                  },
                  data: {
                      id: id
                  }
              }).then((response1) => {
                  if (response1.data.type == 'text/html') {
                      const url = window.URL.createObjectURL(new Blob([response1.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', '准考证.pdf');
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                  } else {
                    console.log(response1.data)
                    if(response1.data.code==503){
                      window.location.reload()
                    }
                      this.$message.error('准考证日期截止')
                  }
              })
          } catch (error) {
              console.error('下载文件失败', error);
          }
      },
      handleSizeChange(newSize) {
          this.pageSize = newSize;
          this.getapplyinfo();
      },
      handleCurrentChange(newPage) {
          this.currentPage = newPage;
          this.getapplyinfo();
      },
      async getapplyinfo(tab) {
    let url = baseURL + '/api/report/getapplyinfo';
    let dataToSend = {
        currentPage: this.currentPage,
        pageSize: this.pageSize
    };
    if (tab) {
        dataToSend.tab = tab;
    }

    try {
        // 设置加载状态为正在加载
        this.isLoading = true;
        console.log('即将发送请求，isLoading设置为：', this.isLoading);

        let response = await axios.post(url, dataToSend, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        // 验证数据结构是否符合预期
        if (Array.isArray(response.data.data) && response.data.data.every((item) => Object.hasOwn(item, 'cname') && Object.hasOwn(item, 'zname'))) {
            await this.$nextTick();
            if(response.data.code==503){
              this.$router.push('/')
            }
            this.applyinfo = response.data.data;
            this.total = response.data.total;
        } else {
            console.error('数据结构不符合预期，无法正确显示数据');
            // 可以在这里根据具体需求进行处理，比如设置一个默认的空数组给applyinfo
            this.applyinfo = [];
        }

        // 数据获取完成，设置加载状态为false
        this.isLoading = false;
        console.log('数据获取完成，isLoading设置为：', this.isLoading);
    } catch (error) {
        console.error('错误:', error);
        // 若出现错误，也设置加载状态为false
        this.isLoading = false;
        console.log('请求出错，isLoading设置为：', this.isLoading);
    }
},
      goToPay(id) {
          axios
             .post(baseURL + '/api/pay/getpaystatus', {id: id}, {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`
                  }
              })
             .then((response) => {
                  console.log(response.data.msg)
                  if (response.data.success == true) {
                      this.$router.push('/payPage/' + id)
                  } else {
                      this.$message.success(response.data.msg);
                      setTimeout(() => {
                          window.location.reload()
                      }, 1000);
                  }
              })
             .catch((error) => {
                  console.error('错误:', error);
              });
      }
  }
}
</script>
<style>

.div {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.company-info {
  display: flex;
  flex-direction: column;
}

.company-name {
  font-size: 20px;
  color: #333;
  background-color:#F0F0F0;
  line-height: 50px;
  text-align: center;
}

.job-title {
  font-size: 14px;
  color: #666;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #ccc;
  line-height: 40px;
}

.div {
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.vertical-list div{
line-height: 60px;
  border-top: 1px solid #ccc;
}
.vertical-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.list-label {
  font-size: 16px;
  color: #666;
  font-weight: bold;
  margin-bottom: 3px;
}

.list-value {
  font-size: 16px;
  color: #333;
}

.div-actions {
  padding: 15px;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-footer {
  padding: 15px;
  border-top: 1px solid #ccc;
  font-size: 16px;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my-tabs{
  width: 450px;
}
.scolor{
  color: red;
}
.list-value2{
  height: 300px;
}
.applyinfo{
  width: 100%;
}
.applyinfo li{
  list-style: none;
  padding-left: 0;  
}
.job-card-vertical2{
  width: 45.9%;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-left: 10px;
}
.mytabss{
  width: 100%;
  display:flex;
  flex-wrap: wrap;
}
.margins{
  padding-left: 3%;
}
</style>