<template>
      <div class="text">
        <h2 class="btime">考试单位：{{detail.company}}</h2>
        <h2 class="btime">考试名称：{{detail.title}}</h2>
        <span class="btime">所属行业：{{detail.profess}}</span><span class="btime">招聘岗位：{{detail.job}}</span>
        <div class="tt">
          <div class="btime">学历要求：{{detail.edu}}</div>
          <div class="btime">工作经验：{{detail.exp}}</div>
          <div class="btime">报名时间：{{detail.zbstarttime}}-{{detail.zbendtime}}</div>
          <div class="btime">准考证打印时间：{{detail.zdstarttime}}-{{detail.zdendtime}}</div>
          <div class="btime">缴费时间：{{detail.paystarttime}}-{{detail.payendtime}}</div>
          <div class="btime">考试时间：{{detail.examstarttime?detail.examstarttime:'待定'}}</div>
          <div class="btime">报名时间：{{detail.zbstarttime}}-{{detail.zbendtime}}</div>

        </div>
      <div class="btime">考场名称：{{detail.position?detail.position.pname:'待定'}}<el-button type="danger" @click="navigateToBaiduMap" style="margin-left:20px;">导航</el-button></div>
      </div>
      <template v-if="detail.brief"><div class="brief" v-html="detail.brief"></div><template wx:else></template></template>
      <el-button 
      type="primary" 
      @click="goToDetail(id)"
      style="width: 100px;margin-bottom:0px;margin-left: 40%;"
     :disabled="disabled">立即报名</el-button>
  </template>
  <style scoped>
.zkzprint{
   margin-left:3rem;
}
.exam{
  margin-left:-10rem;
}
</style>
  <script>
    import axios from 'axios';
    import {baseURL} from '@/api/base.js';
  export default {
    props: ['id'],
    data() {
      return {
        detail: {
          company:'',
          zbstarttime:'',
          zbendtime:'',
          zdstarttime:'',
          zdendtime:'',
          examstarttime:'',
          paystartime:'',
          payendtime:'',
          position:{
            lat:'',
            lng:'',
            address:'',
            pname:''
        }
        },
        pname:'',
        disabled:false,
        zk:''
      };
    },
    mounted() {
      // 模拟从后端获取数据
      this.fetchDetail(this.id);
    },
    methods: {
    goToDetail(id){
      if(this.disabled){
        alert('未到报名时间')
      }else{
        axios.post(baseURL+'/api/zk/getuidzk',{id:id},{headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
      }}).then((response)=>{
         if(!response.data.success){
            if(response.data.code==503){
              window.location.reload()
            }
            this.$message.error(response.data.msg)
         }else{
           this.$router.push('/setReport/'+id)
         }
      })
      }
    },
      fetchDetail(id) {
        axios
        .post(baseURL+'/api/zk/getzkdetail', {id:id},{headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
      }}) //
        .then((response) => {
          this.detail = response.data.data; // 假设后端返回的是项目列表
          if(response.data.data.reporttime){
            this.$nextTick(()=>{ 
               this.disabled=true
            })
          }
        })
        .catch((error) => {
          console.error('错误:', error);
        });  
      },
    navigateToBaiduMap() {
   if (this.detail.position) {
       const longitude = this.detail.position.lng;
       const latitude = this.detail.position.lat;
       const encodedAddress = encodeURIComponent(this.detail.position.pname);
       
       // Define URLs for PC and Mobile
       const baiduMapPcUrl ='http://api.map.baidu.com/marker?location='+latitude+','+longitude+'&title='+encodedAddress+'&content='+encodedAddress+'&output=html&src=webapp.baidu.openAPIdemo';
       const baiduMapMobileUrl = `https://api.map.baidu.com/marker?location=${latitude},${longitude}&title=${encodedAddress}&content=${encodedAddress}&output=html`;
       
       // Detect device and navigate
       const isMobile = /mobile|android|touch|webos|iphone|ipad/i.test(navigator.userAgent);
       if (isMobile) {
           window.location.href = baiduMapMobileUrl;
       } else {
           window.location.href = baiduMapPcUrl;
       }
   }else{
    this.$message.error("该考场处于待定状态，不能导航");
   }
  }
  }
}
  </script>
  <style>
  .titles{
    color:black;
    text-align: center;
  }
  .profess{
    margin-left:40%;
  }
  .job{
    margin-left:40px;
  }
  .tt{
    line-height: 50px;
    text-align: center;
  }
  .brief{
    font-size: 28px;
    text-align: left;
    margin-left:20%;
  }
  .btime{
    text-align: left;
    margin-right:50px;
    line-height: 35px;
  }
  .text{
    margin-left: 40%;
  }
  </style>
  