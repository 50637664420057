<template>
   <div><span class="upmobile">手机号：{{tel}}<span class="red" @click="updatedit(1)">[修改]</span></span><span class="marginleft">密码:{{password}}</span><span class="red" @click="updatedit(3)">【修改】</span></div>
    <div>
      <el-form v-show="status==0" ref="resumeForm" :model="formData" label-width="120px" style="margin-top: 30px;">
        <el-divider><span class="basic-title">基本信息</span></el-divider>
<el-row>
        <!-- 左边列 -->
  <el-col :span="12">
    <el-form-item class="bottom" label="证件照" :rules="[
          { required: true}]">
          <div class="avatar-uploader-wrapper">
            <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
             :http-request="httpRequests"
            :limit="1"
            accept=".png,.jpg,.jpeg"
        >
            <img v-if="formData.avatarUrl" :src="formData.avatarUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        </el-form-item>
    <el-form-item label="真实姓名" :rules="[
          { required: true, message: '真实姓名不能为空', trigger: 'blur' }
        ]">
          <el-input v-model="formData.realname" style="width: 30%;"></el-input>
        </el-form-item>
        <el-form-item label="手机号" :rules="[
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ]">
          <el-input v-model="formData.phone" style="width: 30%;"></el-input>
        </el-form-item>
        <el-form-item label="学历是否全日制" :required="true" prop="is_fulltime">
        <el-select v-model="formData.is_fulltime" placeholder="请选择是否全日制" style="width: 30%;">
          <el-option value="是">是</el-option>
          <el-option value="否">否</el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="性别" :required="true" prop="sex" :rules="[{validator: validateSex, trigger: 'blur' }]">
        <el-select v-model="formData.sex" placeholder="请选择性别" style="width: 30%;">
          <el-option value="男">男</el-option>
          <el-option value="女">女</el-option>
        </el-select>
    </el-form-item>
        <el-form-item label="学历" :required="true">
      <el-select v-model="formData.degree" placeholder="请选择学历" style="width: 30%;">
            <el-option label="高中/中专" value="高中/中专"></el-option>
            <el-option label="专科" value="专科"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="硕士" value="硕士"></el-option>
            <el-option label="博士" value="博士"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="出生年月" :required="true" prop="birthday" :rules="[{validator: validatebirthday, trigger: 'blur' }]">
            <el-date-picker v-model="formData.birthday" type="date" placeholder="选择日期" style="width: 30%;">
        </el-date-picker>
        </el-form-item>
  </el-col>
  <!-- 右边列 -->
  <el-col :span="12">
    <el-form-item label="毕业学校" prop="school">
      <el-input v-model="formData.school" placeholder="请输入毕业学校" style="width: 30%;"></el-input>
    </el-form-item>
    <el-form-item label="专业类型" refs="subject_type"  :rules="[{validator: validatesubjectType, trigger: 'blur' }]">
          <el-select v-model="formData.subject_type" @change="loadMinorCategories2" style="width: 30%;">
      <el-option
        v-for="category in majorCategories"
        :key="category.id"
        :label="category.name"
        :value="category.name"
      />
    </el-select>
    </el-form-item>
    <el-form-item label="专业" refs="subject"   :rules="[{validator: validatesubject, trigger: 'blur' }]">
        <!-- 专业小类下拉框 -->
    <el-select v-model="formData.subject" style="width: 30%;">
      <el-option
        v-for="category in minorCategories"
        :key="category.id"
        :label="category.name"
        :value="category.name"
      />
    </el-select>
    </el-form-item>
  </el-col>
</el-row>
        <el-button class="ebutton" type="primary" @click="submitResume">提交</el-button>
      </el-form>
  <!-- 根据status的值来决定显示哪个部分 -->
  <el-form v-show="status==1" style="padding-left:1rem;">
    <h2>我的资料</h2>
      <el-form-item class="bottom" label="证件照" :rules="[
          { required: true}]">
          <div class="avatar-uploader-wrapper">
            <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
             :http-request="httpRequests"
            :limit="1"
            accept=".png,.jpg,.jpeg"
            :disabled="isEditing === false">
        <template v-if="submittedData.avatar">
            <img :src="submittedData.avatar" class="avatar" />
          </template>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
    </el-form-item>
    <el-form-item label="姓名">
      <el-input v-model="submittedData.realname" :disabled="isEditing === false"></el-input>
    </el-form-item>
    <el-form-item label="电话">
      <el-input v-model="submittedData.phone" :disabled="isEditing === false"></el-input>
    </el-form-item>
    <el-form-item label="毕业学校">
      <el-input v-model="submittedData.school" :disabled="isEditing === false"></el-input>
    </el-form-item>
    <el-form-item label="学历是否全日制">
        <el-select v-model="submittedData.is_fulltime" :disabled="isEditing==false">
          <el-option label="是" value="是" :selected="submittedData.is_fulltime === 0? 'selected' : false" ></el-option>
          <el-option label="否" value="否" :selected="submittedData.is_fulltime === 1? 'selected' : false" ></el-option>
        </el-select>
</el-form-item>
<el-form-item label="专业类型"  :rules="[{validator: validatesubjectType, trigger: 'blur' }]">
          <el-select v-model="submittedData.subject_type" @change="loadMinorCategories" :disabled="isEditing==false">
      <el-option
        v-for="category in majorCategories"
        :key="category.id"
        :label="category.name"
        :value="category.name"
      />
    </el-select>
    </el-form-item>
    <el-form-item label="专业"   :rules="[{validator: validatesubject, trigger: 'blur' }]">
        <!-- 专业小类下拉框 -->
    <el-select v-model="submittedData.subject" :disabled="isEditing==false">
      <el-option
        v-for="category in minorCategories"
        :key="category.id"
        :label="category.name"
        :value="category.name"
      />
    </el-select>
    </el-form-item>
    <el-form-item label="出生年月" prop="birthday" :rules="[{validator: validatebirthday, trigger: 'blur' }]">
            <el-date-picker :disabled="isEditing === false" v-model="submittedData.birthday" type="date" placeholder="选择日期" style="width: 30%;">
        </el-date-picker>
        </el-form-item>
        <el-form-item label="学历">
          <el-select v-model="submittedData.degree" placeholder="请选择学历" :disabled="isEditing === false">
    <!-- 高中/中专选项 -->
    <el-option label="高中/中专" value="高中/中专" :selected="submittedData.degree === '高中/中专'? 'selected' : false" :class="{ 'selected': submittedData.degree === '高中/中专' }"></el-option>
    <!-- 专科选项 -->
    <el-option label="专科" value="专科" :selected="submittedData.degree === '专科'? 'selected' : false" :class="{ 'selected': submittedData.degree === '专科' }"></el-option>
    <!-- 本科选项 -->
    <el-option label="本科" value="本科" :selected="submittedData.degree === '本科'? 'selected' : false" :class="{ 'selected': submittedData.degree === '本科' }"></el-option>
    <!-- 硕士选项 -->
    <el-option label="硕士" value="硕士" :selected="submittedData.degree === '硕士'? 'selected' : false" :class="{ 'selected': submittedData.degree === '硕士' }"></el-option>
    <!-- 硕士选项 -->
    <el-option label="博士" value="博士" :selected="submittedData.degree === '博士'? 'selected' : false" :class="{ 'selected': submittedData.degree === '博士' }"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别">
        <el-select v-model="submittedData.sex" placeholder="请选择性别" :disabled="isEditing === false">
            <el-option label="男" value="男" :selected="submittedData.sex === 0? 'selected' : false" ></el-option>
            <el-option label="女" value="女" :selected="submittedData.sex === 1? 'selected' : false" ></el-option>
      </el-select>
    </el-form-item>
    <template v-if="isEditing === false">
      <el-button @click="changeToEditMode(1)">修改</el-button>
    </template>
    <template v-else>
      <el-button @click="changeToEditMode(2)">不修改</el-button>
    <el-button  @click="submitForm">提交</el-button>
  </template>
      </el-form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { baseURL } from '@/api/base.js';
  import { ElMessage } from 'element-plus';
  import { nextTick } from 'vue';
  export default {
    data() {
      return {
        status: 0, // 标记是否已经提交过
        formData: {
          avatarUrl: '/image-1.png',
          realname:'',
          phone:'',
          degree:'',
          is_fulltime:'',
          school:'',
          sex:'',
          birthday:'',
          subject_type:'',
          subject:''
        },
        additionalInputs: [],
        formErrors: [],  // 错误提示
        initialInput: '',
        uploadUrl:'',
        submittedData: {},
        isEditing: false,
        tel:'',
        idcard:'',
        password:'',
        majorCategories: [
        {
          id: 1,
          name: "哲学",
          minors: [
            { id: 11, name: "哲学" },
            { id: 12, name: "逻辑学" },
            { id: 13, name: "宗教学" },
            { id: 14, name: "伦理学" }
          ]
        },
        {
          id: 2,
          name: "经济学",
          minors: [
            { id: 21, name: "经济学" },
            { id: 22, name: "经济统计学" },
            { id: 23, name: "国民经济管理" },
            { id: 24, name: "资源与环境经济学" },
            { id: 25, name: "财政学" },
            { id: 26, name: "税收学" },
            { id: 27, name: "金融学" },
            { id: 28, name: "金融工程" },
            { id: 29, name: "保险学" },
            { id: 30, name: "投资学" }
          ]
        },
        {
          id: 3,
          name: "法学",
          minors: [
            { id: 31, name: "法学" },
            { id: 32, name: "知识产权" },
            { id: 33, name: "监狱学" },
            { id: 34, name: "信用风险管理与法律防控" },
            { id: 35, name: "国际经贸规则" },
            { id: 36, name: "政治学与行政学" },
            { id: 37, name: "国际政治" },
            { id: 38, name: "外交学" },
            { id: 39, name: "社会学" },
            { id: 40, name: "社会工作" },
            { id: 41, name: "人类学" }
          ]
        },
        {
          id: 4,
          name: "教育学",
          minors: [
            { id: 41, name: "教育学" },
            { id: 42, name: "科学教育" },
            { id: 43, name: "人文教育" },
            { id: 44, name: "教育技术学" },
            { id: 45, name: "学前教育" },
            { id: 46, name: "特殊教育" },
            { id: 47, name: "体育教育" },
            { id: 48, name: "运动训练" },
            { id: 49, name: "社会体育指导与管理" },
            { id: 50, name: "武术与民族传统体育" }
          ]
        },
        {
          id: 5,
          name: "文学",
          minors: [
            { id: 51, name: "汉语言文学" },
            { id: 52, name: "汉语言" },
            { id: 53, name: "汉语国际教育" },
            { id: 54, name: "中国少数民族语言文学" },
            { id: 55, name: "古典文献学" },
            { id: 56, name: "英语" },
            { id: 57, name: "俄语" },
            { id: 58, name: "德语" },
            { id: 59, name: "法语" },
            { id: 60, name: "西班牙语" },
            { id: 61, name: "日语" },
            { id: 62, name: "韩语" },
            { id: 63, name: "新闻学" },
            { id: 64, name: "广播电视学" },
            { id: 65, name: "广告学" },
            { id: 66, name: "传播学" },
            { id: 67, name: "编辑出版学" },
            { id: 68, name: "网络与新媒体" },
            { id: 69, name: "数字出版" }
          ]
        },
        {
          id: 6,
          name: "历史学",
          minors: [
            { id: 61, name: "历史学" },
            { id: 62, name: "世界史" },
            { id: 63, name: "考古学" },
            { id: 64, name: "文物与博物馆学" },
            { id: 65, name: "文化遗产" },
            { id: 66, name: "历史建筑保护工程" }
          ]
        },
        {
          id: 7,
          name: "理学",
          minors: [
            { id: 71, name: "数学与应用数学" },
            { id: 72, name: "信息与计算科学" },
            { id: 73, name: "物理学" },
            { id: 74, name: "应用物理学" },
            { id: 75, name: "化学" },
            { id: 76, name: "应用化学" },
            { id: 77, name: "生物科学" },
            { id: 78, name: "生物技术" },
            { id: 79, name: "生态学" },
            { id: 80, name: "统计学" },
            { id: 81, name: "地理科学" },
            { id: 82, name: "自然地理与资源环境" },
            { id: 83, name: "人文地理与城乡规划" },
            { id: 84, name: "地理信息科学" },
            { id: 85, name: "大气科学" },
            { id: 86, name: "应用气象学" },
            { id: 87, name: "海洋科学" },
            { id: 88, name: "海洋技术" },
            { id: 89, name: "地球物理学" },
            { id: 90, name: "空间科学与技术" }
          ]
        },
        {
          id: 8,
          name: "工学",
          minors: [
            { id: 81, name: "理论与应用力学" },
            { id: 82, name: "工程力学" },
            { id: 83, name: "机械工程" },
            { id: 84, name: "机械设计制造及其自动化" },
            { id: 85, name: "材料成型及控制工程" },
            { id: 86, name: "机械电子工程" },
            { id: 87, name: "工业设计" },
            { id: 88, name: "过程装备与控制工程" },
            { id: 89, name: "车辆工程" },
            { id: 90, name: "汽车服务工程" },
            { id: 91, name: "测控技术与仪器" },
            { id: 92, name: "材料科学与工程" },
            { id: 93, name: "材料物理" },
            { id: 94, name: "材料化学" },
            { id: 95, name: "冶金工程" },
            { id: 96, name: "金属材料工程" },
            { id: 97, name: "无机非金属材料工程" },
            { id: 98, name: "高分子材料与工程" },
            { id: 99, name: "复合材料与工程" },
            { id: 100, name: "能源与动力工程" },
            { id: 101, name: "电气工程及其自动化" },
            { id: 102, name: "电子信息工程" },
            { id: 103, name: "电子科学与技术" },
            { id: 104, name: "通信工程" },
            { id: 105, name: "微电子科学与工程" },
            { id: 106, name: "光电信息科学与工程" },
            { id: 107, name: "信息工程" },
            { id: 108, name: "自动化" },
            { id: 109, name: "计算机科学与技术" },
            { id: 110, name: "软件工程" },
            { id: 111, name: "网络工程" },
            { id: 112, name: "信息安全" },
            { id: 113, name: "物联网工程" },
            { id: 114, name: "数字媒体技术" },
            { id: 115, name: "土木工程" },
            { id: 116, name: "建筑环境与能源应用工程" },
            { id: 117, name: "给排水科学与工程" },
            { id: 118, name: "建筑电气与智能化" },
            { id: 119, name: "城市地下空间工程" },
            { id: 120, name: "道路桥梁与渡河工程" },
            { id: 121, name: "水利水电工程" },
            { id: 122, name: "水文与水资源工程" },
            { id: 123, name: "港口航道与海岸工程" },
            { id: 124, name: "测绘工程" },
            { id: 125, name: "遥感科学与技术" },
            { id: 126, name: "地理空间信息工程" },
            { id: 127, name: "化学工程与工艺" },
            { id: 128, name: "制药工程" },
            { id: 129, name: "资源循环科学与工程" },
            { id: 130, name: "能源服务工程" },
            { id: 131, name: "环境工程" },
            { id: 132, name: "环境科学" },
            { id: 133, name: "环境生态工程" },
            { id: 134, name: "环保设备工程" },
            { id: 135, name: "资源环境科学" },
            { id: 136, name: "生物医学工程" },
            { id: 137, name: "食品科学与工程" },
            { id: 138, name: "食品质量与安全" },
            { id: 139, name: "粮食工程" },
            { id: 140, name: "乳品工程" },
            { id: 141, name: "酿酒工程" },
            { id: 142, name: "葡萄与葡萄酒工程" },
            { id: 143, name: "食品营养与检验教育" },
            { id: 144, name: "烹饪与营养教育" },
            { id: 145, name: "建筑类" },
            { id: 146, name: "安全工程" },
            { id: 147, name: "生物工程" },
            { id: 148, name: "刑事科学技术" },
            { id: 149, name: "消防工程" },
            { id: 150, name: "交通工程" },
            { id: 151, name: "交通运输" },
            { id: 152, name: "交通设备与控制工程" },
            { id: 153, name: "救助与打捞工程" },
            { id: 154, name: "船舶电子电气工程" },
            { id: 155, name: "海洋工程与技术" },
            { id: 156, name: "海洋资源开发技术" },
            { id: 157, name: "兵器类" },
            { id: 158, name: "核工程与核技术" },
            { id: 159, name: "辐射防护与核安全" },
            { id: 160, name: "工程物理" },
            { id: 161, name: "农业工程类" },
            { id: 162, name: "林业工程类" },
            { id: 163, name: "公安技术类" }
          ]
        },
        {
          id: 9,
          name: "农学",
          minors: [
            { id: 91, name: "农学" },
            { id: 92, name: "园艺" },
            { id: 93, name: "植物保护" },
            { id: 94, name: "植物科学与技术" },
            { id: 95, name: "种子科学与工程" },
            { id: 96, name: "设施农业科学与工程" },
            { id: 97, name: "茶学" },
            { id: 98, name: "烟草" },
            { id: 99, name: "应用生物科学" },
            { id: 100, name: "农艺教育" },
            { id: 101, name: "园艺教育" },
            { id: 102, name: "智慧农业" },
            { id: 103, name: "农业资源与环境" },
            { id: 104, name: "野生动物与自然保护区管理" },
            { id: 105, name: "水土保持与荒漠化防治" },
            { id: 106, name: "动物科学" },
            { id: 107, name: "蚕学" },
            { id: 108, name: "蜂学" },
            { id: 109, name: "动物医学" },
            { id: 110, name: "动物药学" },
            { id: 111, name: "动植物检疫" },
            { id: 112, name: "实验动物学" },
            { id: 113, name: "中兽医学" },
            { id: 114, name: "林学" },
            { id: 115, name: "园林" },
            { id: 116, name: "森林保护" },
            { id: 117, name: "经济林" },
            { id: 118, name: "智慧林业" },
            { id: 119, name: "水产养殖学" },
            { id: 120, name: "海洋渔业科学与技术" },
            { id: 121, name: "水族科学与技术" },
            { id: 122, name: "水生动物医学" },
            { id: 123, name: "渔业资源与渔政管理" }
          ]
        },
        {
          id: 10,
          name: "医学",
          minors: [
            { id: 101, name: "基础医学" },
            { id: 102, name: "临床医学" },
            { id: 103, name: "麻醉学" },
            { id: 104, name: "医学影像学" },
            { id: 105, name: "眼视光医学" },
            { id: 106, name: "精神医学" },
            { id: 107, name: "放射医学" },
            { id: 108, name: "口腔医学" },
            { id: 109, name: "预防医学" },
            { id: 110, name: "食品卫生与营养学" },
            { id: 111, name: "中医学" },
            { id: 112, name: "针灸推拿学" },
            { id: 113, name: "藏医学" },
            { id: 114, name: "蒙医学" },
            { id: 115, name: "维医学" },
            { id: 116, name: "壮医学" }
        ],
        }
      ],
        minorCategories:'',
        isComponentMounted: false, // 新增挂载状态标识
      }
    },
    methods: {
      async loadMinorCategories2() {
        await nextTick(); // 等待 DOM 更新
        if(!this.$refs.subject_type)return;
      const subject_type = this.majorCategories.find(
        category => category.name == this.formData.subject_type
      );
      if (subject_type) {
        this.minorCategories = subject_type.minors;
      } else {
        this.minorCategories = [];
      }
    },
      async loadMinorCategories() {
        if (!this.isComponentMounted || !this.$refs.subject) return; // 关键检查
      const subject_type = this.majorCategories.find(
        category => category.name == this.submittedData.subject_type
    );
      console.log(this.submittedData.subject_type)
      if (subject_type) {
        console.log(subject_type.minors)
        this.minorCategories = subject_type.minors;
      } else {
        this.minorCategories = [];
      }
    },
      changeToEditMode(type) {
        if(type==1){
          this.isEditing = true;
        }else{
          this.isEditing = false;
        }

    },
    validatebirthday(rule, value, callback){
        if (!value) {
          callback(new Error('请选择出生年月'));
        } 
      },
      validatesubjectType(rule, value, callback){
        if (!value) {
          callback(new Error('请选择专业名称'));
        } 
      },
    async getuser(){
      var that=this;
      // 这里添加提交表单的具体逻辑，比如发送数据到服务器等
      console.log('提交表单，数据为：', this.submittedData);
      await axios.post(baseURL+'/api/bnotes/getuser', {},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
          }}) //
            .then((response) => {
              if(response.data.code==503){
               window.location.reload()
              }
              if(response.data.code==200){
                 this.tel=response.data.data.tel;
                 this.idcard=response.data.data.idcard;
                 this.password=response.data.data.password;
              }else{
                 this.$message.error(response.data.msg)
              }
            })
            .catch((error) => {
              console.error('错误:', error);
            }); 
    },
    updatedit(tab){
      if(tab==1){
        this.$router.push('UpdatePassword/'+1)
      }else if(tab==2){
        this.$router.push('/UpdateIdCard')
      }else if(tab==3){
        this.$router.push('UpdatePassword/'+3)
      }
    },
    updates(){
      this.$router.push('/UpdatePassword')
    },
    submitForm() {
      var that=this;
      // 这里添加提交表单的具体逻辑，比如发送数据到服务器
      axios.post(baseURL+'/api/bnotes/setbnote', {submitdata:that.submittedData},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
          }}) //
            .then((response) => {
              if(response.data.code==503){
               window.location.reload()
              }
              if(response.data.code==200){
                window.location.reload()
              }else{
                 this.$message.error(response.data.msg)
              }
            })
            .catch((error) => {
              console.error('错误:', error);
            }); 
      // 提交后可根据需求重置状态等，示例如下：
      this.isEditing = false;
    },
      addInput() {
    this.additionalInputs.push({ value: '' });
      },
      removeInput(index) {
        this.additionalInputs.splice(index, 1);
      },
      httpRequests(option){
        const formData = new FormData();
        formData.append('file', option.file); // 上传的文件
  
        // 使用 axios 手动发起请求
        axios({
          method: 'post',
          url: this.uploadUrl,
          data: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          option.onSuccess(response.data); // 成功回调
        })
        .catch(error => {
          option.onError(error); // 错误回调
        });
      },
      async fetchSubmittedResume() {
        await axios.post(baseURL + '/api/bnotes/status',{},{headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}` // 从 localStorage 中获取 token
        }}).then((res)=>{
          if (res.data.status==1) {
            this.status = 1;
            this.submittedData = res.data.data;
            const subject_type = this.majorCategories.find(
        category => category.name == this.submittedData.subject_type
      );

      console.log(this.submittedData.subject_type)
      if (subject_type) {
        console.log(subject_type.minors)
        this.minorCategories = subject_type.minors;
      } else {
        this.minorCategories = '';
      }
        }else if(res.data.code==503){
          this.$router.push('/')
        }
        })
},
      // 上传前的验证
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
  
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      validatesubject(rule, value, callback){
        if (!value) {
          callback(new Error('请输入专业名称'));
        } 
      },
      // 上传成功的回调
      handleAvatarSuccess(response) {
       if(response.status==1&&response.path){
          this.formData.avatar=response.path;
          this.submittedData.avatar=response.path
          this.$message.success('上传成功!');
        } else {
          this.$message.error('上传失败，请重试!');
        }
      },
      async submitResume() {
        let arr=[]
        arr.push(this.formData.exp);
        let extraarr= this.additionalInputs
        extraarr.forEach(item => {
            arr.push(item.value)
        });
        console.log(this.formData)
  try {

    const response = await axios.post(baseURL + '/api/bnotes/submit', this.formData,{headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // 从 localStorage 中获取 token
      }});
    if (response.data.status==1) {
      this.$message.success('资料提交成功!');
      this.submittedData = this.formData;
      window.location.reload();
    } else {
        ElMessage.error(response.data.msg);
    }
  } catch (error) {
    ElMessage.error('网络错误，请稍后再试!');
    console.error(error)
  }
},
      handleclick()
      {
        const inputvalue=this.$refs.uploadRef.$el.querySelector('input[type="file"]')
        if(inputvalue){
            inputvalue.click();
        }
      }
    },
    created() {
      this.getuser()
      // 页面加载时检查是否已经提交过资料
      this.fetchSubmittedResume()
    },
    mounted(){
      this.isComponentMounted = true
      this.uploadUrl = baseURL + '/api/bnotes/upload';
      console.log(this.submittedData)
    },
    beforeUnmount() {
    this.isComponentMounted = false
  }
  };
  </script>
  
  <style scoped>
  .ebutton {
    width: 200px;
    margin-left:35%;
  }
  .avatar-uploader {
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .submitted-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 10px;
  }
  .custom-breadcrumb {
    display: flex;
}
.basic-title{
  color:#87CEEB;
}
.avatar-uploader-wrapper {
    border: 1px solid #000; /* 这里设置边框样式，可根据需要调整颜色、宽度等 */
    display: inline-block; /* 保持元素为内联块级元素，以便与其他元素正常排列 */
}
.center{
  text-align: left;
}
.red{
  color:red;
  cursor:pointer;
}
.marginleft{
  margin-left:10%;
}
.upmobile{
  padding-left:3%;
}
  </style>
  